import React from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import styled from "styled-components";
import ProgressBar from "react-bootstrap/ProgressBar";
import { BsStar, BsStarFill, BsStarHalf } from "react-icons/bs";

const nf = new Intl.NumberFormat("ja-JP", { style: "currency", currency: "JPY" });

const SaleChip = (props) => {
    const { type } = props;
    // 2021/10/16 LIGHTNING_DEAL && primeAccessDuration>0だとプライム会員のみ
    //                  msToStart + lastUpdated : プライム会員開始時間
    //                  msToStart + lastUpdated + Duration : 全員の開始時間
    //                  msToStart + lastUpdated + msToEnd  : 全員の終了時間

    //            LIGHTNING_DEAL && primeAccessDuration==0は、ただの数量限定タイムセール

    // const saleStartTime = itemData.msToStart + itemData.lastUpdated - Date.now();
    // if (saleStartTime > 0 && saleStartTime < itemData.primeAccessDuration * 60000) {
    // if (itemData.type === "LIGHTNING_DEAL" && itemData.primeAccessDuration > 0 && itemData.msToStart + itemData.lastUpdated + itemData.primeAccessDuration * 60 * 1000 > Date.now()) {
    //     return <Chip size="small" label="プライム会員のみ"  className={`${classes.chip} ${classes.p_chip}`} />

    // // プライム会員商品
    // if (type === "LIGHTNING_DEAL" && itemData.primeAccessDuration > 0) {
    //     return <Badge pill>プライム会員のみ</Badge>;
    // } else {
    //     switch (type) {
    //         case "DEAL_OF_THE_DAY":
    //             return <Badge pill>特選タイムセール</Badge>;
    //         case "LIGHTNING_DEAL":
    //             return <Badge pill>数量限定タイムセール</Badge>;
    //         case "BEST_DEAL":
    //             return <Badge pill>お買い得情報</Badge>;
    //         default:
    //             return <Badge pill>{type}</Badge>;
    //     }
    // }
    switch (type) {
        case "DEAL_OF_THE_DAY":
            return (
                <Badge pill bg="primary">
                    特選タイムセール
                </Badge>
            );
        case "LIGHTNING_DEAL":
            return (
                <Badge pill bg="danger">
                    数量限定タイムセール
                </Badge>
            );
        case "BEST_DEAL":
            return (
                <Badge pill bg="success">
                    お買い得情報
                </Badge>
            );
        default:
            return <Badge pill>{type}</Badge>;
    }
};

// itemimg: {
//     position: 'relative',
//     paddingTop: '12px',
//     textAlign: 'center',
//     '& img': {
//         maxWidth: '100%',
//         height: 'auto'
//     }
// },
// 画像
const ItemImageDiv = styled.div`
    position: relative;
    padding-top: 12px;
    text-align: center;
    img {
        max-width: 100%;
        height: auto;
    }
    span {
        position: absolute;
        top: 4px;
        left: 4px;
        font-size: 12;
        font-weight: bold;
        color: "#f0f0f0";
    }
`;
const ItemImage = (props) => {
    const { title, landingUrl, imagePhysicalId } = props;
    const itemUrl = `https://www.amazon.co.jp${landingUrl}` + process.env.REACT_APP_ASSOCIATE_TAG;
    // const itemImgUrl256 = itemData.primaryImage.replace('.jpg', `._AA256_.jpg`);
    // const itemImgUrl128 = itemData.primaryImage.replace('.jpg', `._AA128_.jpg`);
    const itemImgUrl256 = `https://m.media-amazon.com/images/I/${imagePhysicalId}._AA256_.jpg`;
    const itemImgUrl128 = `https://m.media-amazon.com/images/I/${imagePhysicalId}._AA128_.jpg`;

    return (
        <ItemImageDiv>
            <a href={itemUrl} target="_blank" rel="noreferrer">
                <picture>
                    <source media="(min-width: 768px)" srcSet={itemImgUrl256} alt={title} />
                    <img src={itemImgUrl128} alt={title} />
                </picture>
            </a>
            <SaleChip {...props} />
        </ItemImageDiv>
    );
};

// 価格
const Price = styled.h1`
    font-size: 1.25em;
    color: #c12115;
`;
const ItemPrice = (props) => {
    const { dealPrice, minDealPrice, maxDealPrice } = props;
    if (dealPrice == null && minDealPrice == null && maxDealPrice == null) return null;

    const price = dealPrice !== null ? nf.format(dealPrice) : `${nf.format(minDealPrice)} - ${nf.format(maxDealPrice)}`;

    return <Price>{price}</Price>;
};

// 参考価格、過去価格
const Caption = styled.h1`
    font-size: 0.65em;
    color: #2b2b2b;
`;
const ListPrice = (props) => {
    const { basisPrice, basisPriceLabel, savingPercent } = props;
    if (basisPrice == null) return null;

    const listPrice = nf.format(basisPrice);

    return (
        <Caption>
            {basisPriceLabel}:<span style={{ textDecoration: "line-through" }}>{listPrice}</span> ({savingPercent}% OFF)
        </Caption>
    );
};

// 限定数
const LimitedSupply = (props) => {
    const { percentClaimed, totalCouponCount } = props;
    if (percentClaimed == null) return null;

    return (
        <div>
            <ProgressBar now={percentClaimed} label={percentClaimed} visuallyHidden style={{ height: "0.5em" }} />
            <Caption>{`${percentClaimed}%カートに追加済み (${totalCouponCount})`}</Caption>
        </div>
    );
};

const LastTime = (props) => {
    const { type, startTime, endTime } = props;
    if (type !== "LIGHTNING_DEAL") return null;

    // 2021-12-10T15:00:00.000Z
    const startTimeObj = new Date(startTime);
    const endTimeObj = new Date(endTime);

    let label = "";

    if (startTimeObj.getTime() - Date.now() > 30 * 60 * 1000) {
        const sd = new Date(startTimeObj.getTime());
        sd.setMilliseconds(sd.getMinutes() - 30);
        label = `プライム会員のみ ${sd.getHours()}時${sd.getMinutes()}分 開始予定(取下げ可能性あり)`;
    } else if (0 < startTimeObj.getTime() - Date.now() && startTimeObj.getTime() - Date.now() < 30 * 60 * 1000) {
        const remainTime = startTimeObj - Date.now();
        const rm = Math.floor(remainTime / 60000);
        label = `プライム会員のみセール中 残り${rm}分`;
    } else {
        const remainTime = endTimeObj - Date.now();
        const rh = Math.floor(remainTime / 3600000);
        const rm = Math.floor((remainTime % 3600000) / 60000);
        label = `残り時間 ${rh}時間${rm}分`;
    }

    // const primeStartTime = itemData.msToStart + itemData.lastUpdated;
    // const startTime = primeStartTime + itemData.primeAccessDuration * 60 * 1000;
    // let label = '';

    // if (itemData.primeAccessDuration > 0 && Date.now() < primeStartTime) {
    //     const sd = new Date(primeStartTime);
    //     label = `プライム会員のみ ${sd.getHours()}時${sd.getMinutes()}分 開始予定(取下げ可能性あり)`;
    // } else if (itemData.primeAccessDuration > 0 && Date.now() < startTime) {
    //     const remainingTime = startTime - new Date();
    //     const rm = Math.floor(remainingTime / 60000);
    //     label = `プライム会員のみセール中 残り${rm}分`;
    // } else {
    //     const remainingTime = itemData.msToEnd - (Date.now() - itemData.lastUpdated);
    //     const rh = Math.floor(remainingTime / 3600000);
    //     const rm = Math.floor((remainingTime % 3600000) / 60000);
    //     label = `残り時間 ${rh}時間${rm}分`
    // }

    return <Caption>{label}</Caption>;
};

// 参考価格、過去価格
const Rating = styled.span`
    color: var(--bs-yellow);
    font-size: 0.85em;
    span {
        color: var(--bs-blue);
    }
`;
const ItemReview = (props) => {
    const { reviewRating, reviewCount } = props;
    if (reviewRating == null || reviewRating < 3) return;

    // 星の数 Round(Rating*2)/2
    const rate = Math.round(reviewRating * 2) / 2;

    switch (rate) {
        case 3:
            return (
                <Rating title={reviewRating}>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStar />
                    <BsStar />
                    <span> {reviewCount}</span>
                </Rating>
            );
        case 3.5:
            return (
                <Rating title={reviewRating}>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarHalf />
                    <BsStar />
                    <span> {reviewCount}</span>
                </Rating>
            );
        case 4:
            return (
                <Rating title={reviewRating}>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStar />
                    <span> {reviewCount}</span>
                </Rating>
            );
        case 4.5:
            return (
                <Rating title={reviewRating}>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarHalf />
                    <span> {reviewCount}</span>
                </Rating>
            );
        case 5:
            return (
                <Rating title={reviewRating}>
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <BsStarFill />
                    <span> {reviewCount}</span>
                </Rating>
            );
        default:
            break;
    }
};

const Title = styled.span`
    font-size: 0.85em;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: Vertical;
    -webkit-line-clamp: 3;
    a {
        text-decoration: none;
        color: #000000;
        &:hover {
            color: #f50057;
        }
    }
`;
const ItemTitle = (props) => {
    const { title, landingUrl } = props;
    const itemUrl = `https://www.amazon.co.jp${landingUrl}` + process.env.REACT_APP_ASSOCIATE_TAG;

    return (
        <Title title={title}>
            <a href={itemUrl} target="_blank" rel="noreferrer">
                {title}
            </a>
        </Title>
    );
};

function ItemCardComponent(props) {
    return (
        <Card style={{ minHeight: "100%", padding: "2px" }}>
            <ItemImage {...props} />
            <ItemPrice {...props} />
            <ListPrice {...props} />
            <LimitedSupply {...props} />
            <LastTime {...props} />
            <ItemTitle {...props} />
            <ItemReview {...props} />
        </Card>
    );
}

export default ItemCardComponent;
