import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Config from "./config.json";

function ItemSearchForm(props) {
    const { searchItem, setShowCanvas, searchCondition, setSearchCondition, setSearchResult } = props;

    // const onSortChange = (event) => {
    //     setSearchResult({
    //         searchCondition: { ...searchCondition, sortKey: event.target.value },
    //         items: [],
    //     });
    //     setSearchCondition({ ...searchCondition, sortKey: event.target.value });
    // };

    const onPriceChange = (event) => {
        if (/^\d+$/.test(event.target.value) || event.target.value === "") {
            setSearchCondition({ ...searchCondition, [event.target.name]: event.target.value });
        }
    };

    const onSaleTypaChange = (event) => {
        if (event.target.name === "PRIME_EARLY_ACCESS") {
            setSearchCondition({ ...searchCondition, saleType: event.target.checked ? event.target.name : "" });
        } else {
            let ary = searchCondition.saleType.split(",");
            ary = ary[0] === "" ? [] : ary;
            if (event.target.checked) {
                ary = ary.filter((v) => v !== "PRIME_EARLY_ACCESS");
                ary.push(event.target.name);
            } else {
                ary = ary.filter((v) => v !== event.target.name);
            }
            setSearchCondition({ ...searchCondition, saleType: ary.join(",") });
        }
    };

    return (
        <>
            {/* <Form.Group>
                <Form.Label>並び替え</Form.Label>
                <Form.Select onChange={onSortChange} aria-label="Default select example">
                    {Object.entries(Config.sort).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group> */}
            <Form.Group className="mb-3">
                <Form.Label>価格</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="tel"
                        name="minPrice"
                        placeholder="最低価格"
                        value={searchCondition.minPrice}
                        onChange={onPriceChange}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                </InputGroup>
                <InputGroup>
                    <Form.Control
                        type="tel"
                        name="maxPrice"
                        placeholder="最大価格"
                        value={searchCondition.maxPrice}
                        onChange={onPriceChange}
                    />
                    <InputGroup.Text>円</InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>セール種別</Form.Label>
                {Object.entries(Config.saletype).map(([key, value]) => (
                    <Form.Check
                        type="checkbox"
                        key={key}
                        name={key}
                        label={value}
                        checked={searchCondition.saleType.includes(key)}
                        onChange={onSaleTypaChange}
                        aria-label={value}
                    />
                ))}
            </Form.Group>
            <Button
                onClick={() => {
                    setShowCanvas(false);
                    searchItem(0);
                }}
            >
                検索
            </Button>
        </>
    );
}
export default ItemSearchForm;
