import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import ItemCardComponent from "./ItemCardComponent";
import ItemSearchForm from "./ItemSearchForm";
import InfiniteScroll from "react-infinite-scroller";
import Config from "./config.json";
import { BsSearch } from "react-icons/bs";

function App() {
    const [searchText, setSearchText] = useState("");
    const [searchCondition, setSearchCondition] = useState({
        searchText: "",
        sortKey: "",
        minPrice: "",
        maxPrice: "",
        saleType: "DEAL_OF_THE_DAY,LIGHTNING_DEAL,BEST_DEAL",
        categoryIds: [],
    });
    const [searchResult, setSearchResult] = React.useState({
        searchCondition: {},
        items: [],
    });
    const [showCanvas, setShowCanvas] = useState(false);

    const inputEl = useRef(null);

    const handleItemName = (e) => {
        setSearchCondition({ ...searchCondition, searchText: e.target.value });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            searchItem();
            e.preventDefault();
        }
    };

    const onSortChange = (event) => {
        setSearchResult({
            searchCondition: { ...searchCondition, sortKey: event.target.value },
            items: [],
        });
        setSearchCondition({ ...searchCondition, sortKey: event.target.value });
    };

    const searchItem = (page = 0) => {
        // if (searchCondition.searchText.trim() === "") {
        //     return;
        // }

        axios
            .get(process.env.REACT_APP_API_URL, {
                params: {
                    ...searchCondition,
                    page: page,
                },
            })
            .then((response) => {
                if (page === 0) {
                    setSearchText(searchCondition.searchText.trim());
                    setSearchResult({
                        items: [...response.data],
                        hasMoreItem: response.data.length !== 0,
                    });
                } else {
                    setSearchResult({
                        items: [...searchResult.items, ...response.data],
                        hasMoreItem: response.data.length !== 0,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        inputEl.current.focus();
        searchItem(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCondition.sortKey]);

    return (
        <Container fluid>
            {/* <Row>
                <Col className="h4">アマ探 - Amazonタイムセール検索 最安値を探そう！</Col>
            </Row> */}
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            ref={inputEl}
                            placeholder="何をお探しですか？"
                            onChange={handleItemName}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={() => searchItem(0)}
                            aria-label="検索"
                        >
                            <BsSearch />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col md="auto" className="py-1 d-none d-md-block">
                    <ItemSearchForm
                        {...{ searchItem, setShowCanvas, searchCondition, setSearchCondition, setSearchResult }}
                    />
                    <hr />
                    <Card style={{ width: 280 }}>
                        <a href="https://fritoku.com/" target="_blank" rel="noopener noreferrer">
                            <Card.Img
                                width="278"
                                height="145"
                                src="https://fritoku.com/wpside_400x210.webp"
                                alt="フリトク"
                            />
                        </a>
                        <Card.Body>
                            <Card.Text>
                                フリマサイト横断検索に特化したサイトです。最安値の限定品をゲットしましょう！！
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <hr />
                    <Card style={{ width: 280 }}>
                        <a href="https://rs-techdev.com/archives/4340" target="_blank" rel="noopener noreferrer">
                            <Card.Img
                                width="278"
                                height="156"
                                src="https://rs-techdev.com/wp-content/uploads/2022/08/amazonCalc-320x180.webp"
                                alt="Amazon年間購入金額を知る方法"
                            />
                        </a>
                        <Card.Body>
                            <Card.Text>生涯購入金額も確認できます。数字で散財を確認したい方はクリック！！</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Row className="py-1">
                        <Col className="d-none d-md-block">
                            <h4>{searchText === "" ? "" : searchText + " の検索結果"}</h4>
                        </Col>
                        <Col md="auto" className="d-none d-md-block">
                            <Form.Select onChange={onSortChange} aria-label="Default select example">
                                {Object.entries(Config.sort).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-md-none">
                            <h4>{searchText === "" ? "" : searchText + " の検索結果"}</h4>
                        </Col>
                    </Row>
                    <Row className="py-1">
                        <Col className="d-md-none">
                            <Form.Select onChange={onSortChange} aria-label="Default select example">
                                {Object.entries(Config.sort).map(([key, value]) => (
                                    <option key={key} value={key}>
                                        {value}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col xs="auto" className="d-md-none">
                            <Button onClick={() => setShowCanvas(true)}>絞り込み</Button>
                        </Col>
                    </Row>
                    <InfiniteScroll
                        initialLoad={false}
                        loadMore={(page) => searchItem(page)}
                        hasMore={searchResult.hasMoreItem}
                    >
                        <Row className="g-1">
                            {searchResult.items?.map((item) => (
                                <Col key={item.dealId} xs={6} md={2}>
                                    <ItemCardComponent {...item} />
                                </Col>
                            ))}
                        </Row>
                    </InfiniteScroll>
                </Col>
            </Row>
            <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>絞り込み</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ItemSearchForm
                        {...{ searchItem, setShowCanvas, searchCondition, setSearchCondition, setSearchResult }}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </Container>
    );
}

export default App;
